import React from 'react';

export default class Main extends React.PureComponent {

    public render() {
        return (
            <div>
                <div>This is not a website.</div>
            </div>
        )
    }
}